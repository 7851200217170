// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page {
  font-family: Katibeh;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
}

.header {
  font-family: Katibeh !important;
  font-size: 32px;
  font-weight: 400;
  line-height: 44.8px;
  text-align: right;
}

.input-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.required {
  color: red;
  margin-left: 5px;
}

input:invalid,
select:invalid {
  border: 1px solid red;
}

/*.input-group {*/
/*  margin-bottom: 16px;*/
/*}*/
/* 
.input-error {
  border: 1px solid #e74c3c; 
} 
*/

.error-message {
  color: #e74c3c; /* Red color for error message */
  font-size: 12px;
  margin-top: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Supcomponents/Forms/NewEmployeeForm.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;EAC/B,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,UAAU;EACV,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;;EAEE,qBAAqB;AACvB;;AAEA,iBAAiB;AACjB,yBAAyB;AACzB,IAAI;AACJ;;;;CAIC;;AAED;EACE,cAAc,EAAE,gCAAgC;EAChD,eAAe;EACf,eAAe;AACjB","sourcesContent":[".page {\n  font-family: Katibeh;\n  font-size: 24px;\n  font-weight: 400;\n  line-height: 33.6px;\n}\n\n.header {\n  font-family: Katibeh !important;\n  font-size: 32px;\n  font-weight: 400;\n  line-height: 44.8px;\n  text-align: right;\n}\n\n.input-container {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 20px;\n  justify-content: space-around;\n}\n\n.column {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n.error-message {\n  color: red;\n  font-size: 14px;\n  margin-top: 5px;\n}\n\n.required {\n  color: red;\n  margin-left: 5px;\n}\n\ninput:invalid,\nselect:invalid {\n  border: 1px solid red;\n}\n\n/*.input-group {*/\n/*  margin-bottom: 16px;*/\n/*}*/\n/* \n.input-error {\n  border: 1px solid #e74c3c; \n} \n*/\n\n.error-message {\n  color: #e74c3c; /* Red color for error message */\n  font-size: 12px;\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
