import React, {useState} from 'react';
import TreeComponent from "../../../../Components/Supcomponents/Tree.component";
import TableWithBtns from "../../../../Tables/TableWithBtns";
import DefaultBtn from "../../../../Components/Supcomponents/Buttons/DefaultBtn";
import AddAccountModal from "../../../../modals/Accounts/AddAccount.modal";

function TweeterSocial(props) {
    const [isAddAccountModalOpen,setIsAddAccountModalOpen] = useState(false);

    const displayAddAccountModal = () =>{
        setIsAddAccountModalOpen(!isAddAccountModalOpen);
    }
    return (
        <>
        <div className={"w-full flex flex-col md:flex-row gap-10 p-5 py-10"}>
            <TreeComponent classNameContener={"md:w-[23%] w-full  gap-5 pr-5"} className={"w-[90%]"} title={"الحسابات"}/>
            <div className={"flex-1"}>
                <div className={"flex w-full gap-2 flex-wrap"}>
                    <DefaultBtn className={"w-auto bg-primary-btn text-primary rounded-md"} classBtn={"w-auto"} title={"بوست"} />
                    <DefaultBtn className={"w-auto bg-primary-btn text-primary rounded-md"} classBtn={"w-auto"} title={"فولو"} />
                    <DefaultBtn className={"w-auto bg-primary-btn text-primary rounded-md"} classBtn={"w-auto"} title={"ستوري"} />
                    <DefaultBtn className={"w-auto bg-primary-btn text-primary rounded-md"} classBtn={"w-auto"} title={"لايك"} />
                    <DefaultBtn className={"w-auto bg-primary-btn text-primary rounded-md"} classBtn={"w-auto"} title={"ريبلاي"} />
                    <DefaultBtn className={"w-auto bg-primary-btn text-primary rounded-md"} classBtn={"w-auto"} title={"مشاهدات"} />
                    <DefaultBtn className={"w-auto bg-primary-btn text-primary rounded-md"} classBtn={"w-auto"} title={"مشاركة"} />
                    <DefaultBtn className={"w-auto bg-primary-btn text-primary rounded-md"} classBtn={"w-auto"} title={"ريل"} />
                    <DefaultBtn className={"w-auto bg-primary-btn text-primary rounded-md"} classBtn={"w-auto"} title={"عرض"} />
                    <DefaultBtn className={"w-auto bg-primary-btn text-primary rounded-md"} classBtn={"w-auto"} title={"فحص"} />
                    <DefaultBtn className={"w-auto bg-primary-btn text-primary rounded-md"} classBtn={"w-auto"} title={"الغاء قفل"} />
                </div>
                <TableWithBtns
                    departmentIcon={true}

                    handelDepartmentIcon={displayAddAccountModal}
                    deleteIcon={true}
                    deleteContent={"حذف الحسابات المحددة"}
                    deleteId={"deleteSomeAccounts"}
                    DeleteRed={false}
                    handelDeleteIcon={() => {
                    }}
                    plusIconId={"add-new-account"}
                    plusIconContent={"اضافة حسابات جديدة"}
                    isInput={true}
                    inputTitle={"بحث"}
                    inputName={"search"}
                    inputValue={""}
                    classNameHeader={"items-center"}
                    inputClass={"my-5 w-1/3"}

                >
                    <thead
                        className="table-font text-primary text-gray-900 uppercase dark:text-gray-400 title-table-font">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            <input type={"checkbox"} name={""} onChange={() => {
                            }}/>
                        </th>
                        <th scope="col" className="px-6 py-3">
                            اسم الحساب
                        </th>
                        <th scope="col" className="px-6 py-3">
                            الموقع
                        </th>
                        <th scope="col" className="px-6 py-3">
                            البيو
                        </th>
                        <th scope="col" className="px-6 py-3">
                            عدد التويتات
                        </th>
                        <th scope="col" className="px-6 py-3">
                            عدد المتابعين
                        </th>
                        <th scope="col" className="px-6 py-3">
                            عدد المتابعون
                        </th>
                        <th scope="col" className="px-6 py-3">
                           الحالة
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="">
                    <td className="px-6 py-4">
                            <input type={"checkbox"} name={""} onChange={() => {
                            }}/>
                        </td>
                        <td className="px-6 py-4">تواصل اجتماعي</td>
                        <td className="px-6 py-4">حازم</td>
                        <td className="px-6 py-4">5</td>
                        <td className="px-6 py-4">25</td>
                        <td className="px-6 py-4">متفاعل</td>
                        <td className="px-6 py-4 text-red-600">10</td>
                        <td className="px-6"></td>
                    </tr>
                    </tbody>
                </TableWithBtns>
            </div>
        </div>

            <AddAccountModal  isModalOpen={isAddAccountModalOpen} onClose={displayAddAccountModal} />
        </>
    );
}

export default TweeterSocial;