import React, {useEffect, useState} from "react";
import ChartsHomeComponent from "../Charts.home.component";
import DefultTable from "../../../Tables/DefultTable";
import { useDispatch, useSelector } from "react-redux";
import GetTasksAction from "../../../redux/action/Task/GetTasks.action";

function HomeTap(props) {
  const dispatch = useDispatch();
  const tasksItems = useSelector((state) => state.tasks);
  const tasks = tasksItems.data || []; // Default to empty array if data is not available
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    dispatch(GetTasksAction({ page: 1 ,limit:10}));
  };
  useEffect(() => {
    dispatch(GetTasksAction({ page: 1 ,limit :10}));
  }, [dispatch]);

  return (
    <>
      <ChartsHomeComponent />
      <DefultTable
          totalItems={tasks.length}
          itemsPerPage={10}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          data={tasks} />
    </>
  );
}

export default HomeTap;
