import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, List, ListItem } from "@mui/material";
import SearchInput from "../../../../Components/Supcomponents/inputs/SearchInput";
import MeetingCard from "./MeetingCard";
import NewMeeting from "./NewMeeting";
import NewMeetingIcon from "../../../../assets/icons/meetings/meeting.png";

function MeetingsList(props) {
  const [activeCard, setActiveCard] = useState("");
  const [isNewMeetingModal, setIsNewMeetingModal] = useState(false);
  const meetingsList = [
    { title: "اجتماع مراجعة المحتوى", date: "12/7/2024" },
    { title: "اجتماع نشر المحتوى", date: "20/8/2024" },
    { title: "اجتماع نشر المحتوى", date: "20/8/2024" },
    { title: "اجتماع نشر المحتوى", date: "20/8/2024" },
  ];

  const handleClick = (e, title, date) => {
    console.log("handle click date" + date);

    const id = e.currentTarget.id;
    setActiveCard(id);
    props.changeData({ title: title, date: date });
  };

  const handelNewMeetingModal = () => {
    setIsNewMeetingModal(!isNewMeetingModal);
  };

  useEffect(() => {
    setActiveCard("0");
    props.changeData(meetingsList[0]);
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          md: "32%",
          paddingLeft: 2,
          borderLeft: 2,
          borderColor: "grey.200",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6">اجتماعات سابقة</Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <IconButton onClick={handelNewMeetingModal}>
              <img
                src="https://s3-alpha-sig.figma.com/img/624e/382a/20b24e58ac3596ed998c6b95dd9c5066?Expires=1725235200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=q-Mqh1IiyKv8IHMgpRqqpGqAeXBTdAXdLzMM8Nr~gy1HC4m49~k29W7~sdS9~rDED7p1afOyZGS5C8chZQxUP3nyCQ6ct7f2PbupbFGLFOedB9IHj9mKygjwSA1zvatplqjcyWnAFFZvQrngNgtwsWpOHudt1FdmxfURWV8UaNh2B4H9XNLRb3mbqsaIMZQ~-kG6HtlX8-ihLLfLJ8fe4VayQOgySU8mp8AzQ03dIlYl0bwn7lgR1pfkmdMO550OTJu3tq78~jMxchjqL0dqDmeMdb2b-HiC~pd0aePO0oAWUWn5vKgk5FAHnaS1gJ6PEfwvu4aUlC0bkGAGIUccuw__"
                alt="img"
                className={"w-[25px] h-[25px]"}
              />
            </IconButton>
          </Box>
        </Box>
        <SearchInput />
        <List>
          {meetingsList.map((element, index) => (
            <ListItem key={index} sx={{ mb: 1 }}>
              <MeetingCard
                id={index.toString()}
                title={element.title}
                date={element.date}
                onClick={(e) => handleClick(e, element.title, element.date)}
                className={
                  activeCard === index.toString() ? "bg-[#A6CDD74D]" : ""
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <NewMeeting
        isModalOpen={isNewMeetingModal}
        onClose={handelNewMeetingModal}
      />
    </>
  );
}

export default MeetingsList;
