import React, {useEffect, useState} from "react";
import TaskCardComponent from "../TeamWorkComponents/TaskCard.component";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// import TaskCalendarComponent
import TaskCalendarComponent from "../TeamWorkComponents/TaskCalendar.component";
import GetTasksAction from "../../../redux/action/Planner/GetTasks.action";
import GetTasksCardsAction from "../../../redux/action/Task/GetTasksCards.action";
import {useDispatch, useSelector} from "react-redux";

function TeamWorkPlanningTap(props) {
  const planner = useSelector((state) => state.planner);
  const tasks = planner.data;
  if (planner.date) {
    planner.date = planner.date.slice(0, 10);
  }
  const dateWithTime = new Date();
  const [date, setDate] = useState(new Date(dateWithTime.getFullYear(), dateWithTime.getMonth(), dateWithTime.getDate()));
  const dispatch = useDispatch();
  const onChange = date => {
    setDate(date);
  };

  useEffect(() => {
    dispatch(GetTasksAction(date));
  } , [date])

  // Example task data

  return (
    <div className={"w-full flex justify-between mt-10 flex-wrap"}>
      <div className={"w-6/12 flex flex-col gap-5 pr-10"}>
        <h3 className={"font-bold text-md"}> مهام تاريخ {planner.date}</h3>
        <div className={"flex flex-col gap-4 max-h-full"}>
          <TaskCardComponent tasks={tasks} />
        </div>
      </div>
      <div className={"flex flex-col items-center gap-6"}>
        <Calendar onChange={onChange} value={date} />
        <div className={"flex flex-col w-full"}>
          <div className={"flex flex-col gap-3"}>
            <div className={"flex justify-between px-2"}>
              <h3 className={"font-bold text-md"}>المهام</h3>
              <div className={"icons-tasks flex gap-3"}>
                <i className="fa-regular fa-pen-to-square"></i>
                <i className="fa-solid fa-circle-plus"></i>
              </div>
            </div>
            <div className={"flex flex-col gap-3 w-full"}>
              {tasks.map((task, index) => (
                <TaskCalendarComponent task={task}/>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamWorkPlanningTap;
