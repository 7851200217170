// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ActionIcons.css */
.icon {
  cursor: pointer;
}

.icon:hover {
  color: #498696; /* Change color on hover, adjust as needed */
  transform: scale(1.1); /* Optional: Slightly increase the size on hover */
  transition: color 0.3s, transform 0.3s; /* Smooth transition */
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManageTasks/components/thirdUtils/ActionIcons.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;EACE,eAAe;AACjB;;AAEA;EACE,cAAc,EAAE,4CAA4C;EAC5D,qBAAqB,EAAE,kDAAkD;EACzE,sCAAsC,EAAE,sBAAsB;AAChE","sourcesContent":["/* ActionIcons.css */\n.icon {\n  cursor: pointer;\n}\n\n.icon:hover {\n  color: #498696; /* Change color on hover, adjust as needed */\n  transform: scale(1.1); /* Optional: Slightly increase the size on hover */\n  transition: color 0.3s, transform 0.3s; /* Smooth transition */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
