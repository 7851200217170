import React from "react";
import { Box, IconButton } from "@mui/material";
import user1Icon from "../../../assets/icons/users/user1.png";
import EditIconBtn from "../../../Components/Supcomponents/Buttons/EditIconBtn";
import DeleteIconBtn from "../../../Components/Supcomponents/Buttons/DeleteIconBtn";
import clockIcon from "../../../assets/icons/clock.png";

const TaskCardComponent = ({ task }) => {
  return (
    <div className="task-card bg-white p-3">
      <div className="task-card-content gap-2">
        <Box className="task-card-header flex justify-between items-center">
          <div className="text-[24px] flex items-center font-medium">
            <p color="text.primary">{task.description}</p>
          </div>
          <Box className="task-card-avatar flex items-center">
            <Box className="task-card-avatar-box flex gap-2 items-center ">
              <img
                alt={"img"}
                src={
                  "https://s3-alpha-sig.figma.com/img/fbc6/027a/7937172e89c3698f421eaed8b892d0bb?Expires=1725235200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Oi4QtZSB4xHCGJYJ7BEJtR1ekhmK5UgAXU7jGHKRCUT0zv5reKBdNQxRX73WOXd1mco-bro6FKn4W-VddBspm6rvEC5ldzMdTkZKu7nIymE9xD4yse4pUUjY5QJHMIMfcUGB9q-iDYSE33SetGeB808vjJmi4gBmX8RxlXChpJ9p8vnBxurw17w-Yhikb-bSZ~p0ueBIaMSmGoz79m1POK9ttvetzqPvmQYfiFkG65JnAvzUk6gQGV4Fw5ygafZXx88zx-1EWHe9WorcH31gDoBCI134y3Iv8TVhfIUaUZQ73o-iB~0vP7EPB38E79KquwMdCmIf266KYKYWgVYwog__"
                }
                className={"w-[25px] h-[25px] rounded-full"}
              />
              {/*<Avatar className="ml-3" src={task.imgUrl} alt={task.name}/>*/}
              <p className="task-card-name ">{task.name}</p>
            </Box>
          </Box>
        </Box>
        <Box className="task-card-footer">
          <Box className="task-card-due-date flex w-2/4 justify-center items-center gap-3 ">
            <p className={"text-center"}>{task.dueDate}</p>
            <img
              alt={"img"}
              src={
                "https://s3-alpha-sig.figma.com/img/e600/09be/894cf97f2ece2af35aa0300bd5906c9a?Expires=1725235200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=GJoj~n5vkYH3znVb3CYLfIagZaKntPXyScy8ywRA4xfS9Kp~GZWVedI0OhIfp2ypMujZKAkL8HH0F7IH0lg3AM11cHgaP3w6OpiDb8dN1AdEiIhwYUs6AeznqP~S0KXfXiExZttBpyL0MsQII7-0iuDSJxEy96cjuCVXSxvF8kwRblScxI2ErYinWzMAeXDnxgfaaAIbrIFLPz8rbyEUPbD1vYkv2qI4gfDFMydF1H-pTayd2h6DJTyvhAp2sUWBHsnjENWc-cuIMxNwljcUd9etP5UC68W1NONgYbQ1cRGx2ew7dXyY1pc2ikSR-upUdOvjT8r4UCAihJ71Hmu~Eg__"
              }
              className={"w-[18px] h-[18px]"}
            />
          </Box>
          <Box className={"flex items-end"}>
            <IconButton aria-label="edit" size="small" sx={{ marginRight: 1 }}>
              <EditIconBtn className={"text-[13px]"} />
            </IconButton>
            <IconButton aria-label="delete" size="small">
              <DeleteIconBtn className={"text-[13px]"} />
            </IconButton>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default TaskCardComponent;
