// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust based on your layout */
}

.searchContainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  direction: rtl;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0 8px;
  height: 40px;
}

.searchIcon {
  width: 25px;
  height: 25px;
}

.divider {
  height: 20px;
  width: 1%;
  background-color: #ccc;
  margin: 0 8px;
}

.searchInput {
  border: none;
  outline: none;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Tables/style.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,kCAAkC;AACpC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,gCAAgC;AACjD;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,cAAc;EACd,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,UAAU;AACZ","sourcesContent":[".spinner {\n  border: 4px solid rgba(0, 0, 0, 0.1);\n  border-radius: 50%;\n  border-top: 4px solid #3498db;\n  width: 24px;\n  height: 24px;\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n.loading-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100px; /* Adjust based on your layout */\n}\n\n.searchContainer {\n  display: flex;\n  align-items: center;\n  margin-bottom: 16px;\n  direction: rtl;\n  border: 1px solid #ccc;\n  border-radius: 8px;\n  padding: 0 8px;\n  height: 40px;\n}\n\n.searchIcon {\n  width: 25px;\n  height: 25px;\n}\n\n.divider {\n  height: 20px;\n  width: 1%;\n  background-color: #ccc;\n  margin: 0 8px;\n}\n\n.searchInput {\n  border: none;\n  outline: none;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
