import React, {useState} from 'react';
import DefaultModal from "../DefaultModal";
import TextInput from "../../Components/Supcomponents/inputs/TextInput";
import DefaultSelect from "../../Components/Supcomponents/inputs/DefaultSelect";
import DefaultBtn from "../../Components/Supcomponents/Buttons/DefaultBtn";
import DefaultFileInput from "../../Components/Supcomponents/inputs/DefaultFileInput";

function AddAccountModal(props) {
    const [selectedFile, setSelectedFile] = useState(null);

    const onChangeFunction = (event) => {
        console.log(event)
        setSelectedFile(event.target.files[0]);
    };
    return (
        <DefaultModal classNameModal={"w-full md:w-[28%]"} isModalOpen={props.isModalOpen} title={"اضافة حساب"} onClose={props.onClose} >
            <div className="flex flex-col py-15 ">
                <div className={"inputs w-full px-3 flex flex-col items-centers gap-2.5"}>
                    <DefaultFileInput
                        name="image"
                        onChange={(event) => onChangeFunction(event)}
                        value={selectedFile}
                        titleBoxPrimary={" ملف CSV"}
                        title=" لا يوجد ملفات... "
                        fullWidth
                    />
                    <DefaultSelect className={"w-full rounded-[11px]"} classNameSelect={"select-arrow-left py-2 "}
                                   name={"category"} value={""} title={"اختر فئة"}/>
                    <DefaultSelect className={"w-full rounded-[11px]"} classNameSelect={"select-arrow-left py-2"}
                                   name={"category"} value={""} title={"اسم الموظف المسؤول"}/>
                </div>
                <DefaultBtn className={"mb-10 mt-3 justify-center "} title={"رفع الحسابات"}/>
            </div>
        </DefaultModal>
    );
}

export default AddAccountModal;