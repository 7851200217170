import React, {useState} from 'react';
import DefaultModal from "../../../../modals/DefaultModal";
import EditIconBtn from "../../../../Components/Supcomponents/Buttons/EditIconBtn";
import DeleteIconBtn from "../../../../Components/Supcomponents/Buttons/DeleteIconBtn";
import AddIcon from "@mui/icons-material/Add";

const CardTask = (props) => {
    const [selectChecked,setSelectChecked] = useState(false)
    return (
        <div className={"bg-white rounded-lg flex p-2 items-baseline gap-2"}>
            <input type={"checkbox"} name={""} checked={selectChecked} onChange={() => {
                setSelectChecked(!selectChecked)
            }}/>
            <div className={"flex flex-col w-full gap-2"}>
                <div className={"flex justify-between items-center"}>
                    <p>مهمة تحليل بيانات</p>
                    <div className={"flex gap-1 items-center"}>
                        <img alt={"img"}
                             src={"https://s3-alpha-sig.figma.com/img/fbc6/027a/7937172e89c3698f421eaed8b892d0bb?Expires=1725235200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Oi4QtZSB4xHCGJYJ7BEJtR1ekhmK5UgAXU7jGHKRCUT0zv5reKBdNQxRX73WOXd1mco-bro6FKn4W-VddBspm6rvEC5ldzMdTkZKu7nIymE9xD4yse4pUUjY5QJHMIMfcUGB9q-iDYSE33SetGeB808vjJmi4gBmX8RxlXChpJ9p8vnBxurw17w-Yhikb-bSZ~p0ueBIaMSmGoz79m1POK9ttvetzqPvmQYfiFkG65JnAvzUk6gQGV4Fw5ygafZXx88zx-1EWHe9WorcH31gDoBCI134y3Iv8TVhfIUaUZQ73o-iB~0vP7EPB38E79KquwMdCmIf266KYKYWgVYwog__"}
                             className={"w-[15px] h-[15px] rounded-full"}
                        />
                        {/*<Avatar className="ml-3" src={task.imgUrl} alt={task.name}/>*/}
                        <p className="">كريم</p>
                    </div>
                </div>
                <div className={"flex gap-1 items-baseline"}>
                    <div className={"w-[8px] h-[8px] bg-[#49869699] rounded-full"}></div>
                    <p className={"text-gray-400 text-[15px]"}>{props.text}</p>
                </div>
            </div>
        </div>
    )
}

function TasksOFMeeting(props) {
    return (
        <DefaultModal
            classNameModal={"w-full md:w-[35%] border border-[#49869633] rounded-2xl px-10 py-8"}
            isModalOpen={props.isModalOpen}
            onClose={props.onClose}
            isTitle={false}
        >
            <div className={"w-full flex flex-col gap-4 justify-center"}>
                <div className={"w-full flex justify-between"}>
                    <p className={"text-primary"}>النقاط الاساسية التي ذكرت بالاجتماع </p>
                    <div className={"flex gap-2"}>
                        <EditIconBtn/>
                        <DeleteIconBtn/>
                    </div>
                </div>
                <div className={"bg-[#A6CDD733] rounded-2xl flex flex-col gap-3 px-10 py-4"}>
                    <CardTask text={"نشر محترف للبيانات ومعرفة تفاصيل المهمة"}/>
                    <CardTask text={"استخدام برامج سهلة التعديل"}/>
                    <CardTask text={"استخدام برامج سهلة التعديل"}/>

                    <div className={"bg-[#49869626] flex p-2 rounded-lg cursor-pointer"}>
                        <AddIcon/>
                        <p className={"text-primary"}>اضافة مهمة جديدة..</p>
                    </div>
                </div>
            </div>
        </DefaultModal>
    );
}

export default TasksOFMeeting;