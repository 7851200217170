// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p{
    font-family: "Katibeh", serif;
    font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/chat/components/Meetings/MenuMeeting.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,eAAe;AACnB","sourcesContent":["p{\n    font-family: \"Katibeh\", serif;\n    font-size: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
