// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-hlgwow {
    display: flex !important; /* استخدم !important لتجاوز الأنماط الافتراضية */
    align-items: center; /* إعادة تعيين محاذاة العناصر */
    height: 2rem;
}

.css-mohuvp-dummyInput-DummyInput {
    height: 0; /* يمكنك تحديد الارتفاع المناسب هنا */
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Supcomponents/inputs/CustomSelect.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB,EAAE,gDAAgD;IAC1E,mBAAmB,EAAE,+BAA+B;IACpD,YAAY;AAChB;;AAEA;IACI,SAAS,EAAE,qCAAqC;IAChD,UAAU;IACV,SAAS;IACT,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".css-hlgwow {\n    display: flex !important; /* استخدم !important لتجاوز الأنماط الافتراضية */\n    align-items: center; /* إعادة تعيين محاذاة العناصر */\n    height: 2rem;\n}\n\n.css-mohuvp-dummyInput-DummyInput {\n    height: 0; /* يمكنك تحديد الارتفاع المناسب هنا */\n    padding: 0;\n    margin: 0;\n    border: none;\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
