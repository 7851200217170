// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-18yfxbv-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-right: 0 !important;
}

.css-18yfxbv-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
    padding-right: 0 !important;
}

.MuiAutocomplete-hasPopupIcon.css-108wi7k-MuiAutocomplete-root .MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-108wi7k-MuiAutocomplete-root .MuiOutlinedInput-root{
    padding-right: 10px !important;
    font-size: 20px;
    height: 2.5rem !important;

}

.css-smubyb-MuiGrid-root .MuiGrid-item {
    padding-top: 0 !important; /* Override any top padding */
}

.MuiInputBase-input::placeholder {
    font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Supcomponents/inputs/SelecteMultiTag.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,8BAA8B;IAC9B,eAAe;IACf,yBAAyB;;AAE7B;;AAEA;IACI,yBAAyB,EAAE,6BAA6B;AAC5D;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".css-18yfxbv-MuiAutocomplete-root .MuiOutlinedInput-root {\n    padding-right: 0 !important;\n}\n\n.css-18yfxbv-MuiAutocomplete-root .MuiAutocomplete-inputRoot {\n    padding-right: 0 !important;\n}\n\n.MuiAutocomplete-hasPopupIcon.css-108wi7k-MuiAutocomplete-root .MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-108wi7k-MuiAutocomplete-root .MuiOutlinedInput-root{\n    padding-right: 10px !important;\n    font-size: 20px;\n    height: 2.5rem !important;\n\n}\n\n.css-smubyb-MuiGrid-root .MuiGrid-item {\n    padding-top: 0 !important; /* Override any top padding */\n}\n\n.MuiInputBase-input::placeholder {\n    font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
