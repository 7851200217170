import React from "react";
import profileIcon from "../../../assets/icons/profile.png";
import { Avatar } from "@mui/material";

function TaskCardComponent({ tasks }) {
  return (
    <div className="w-full bg-[#4986961A] rounded-lg py-5 px-5 font-family-primary">
      {tasks.map((task, index) => (
        <React.Fragment key={index}>
          <div className="flex items-center gap-6 mb-6 last:mb-0">
            <div className="info-task flex flex-col w-2/3">
              <text className="font-bold text-[18px]">{task.name}</text>
              <div className="description-task flex flex-col gap-3 mt-3">
                <h4 className="text-[16px] font-semibold">التفاصيل</h4>
                <p className="text-[16px] text-gray-400">
                  <span dangerouslySetInnerHTML={{__html: task.description}}/>
                </p>
              </div>
            </div>
            <div className="info-user flex items-center gap-4 text-right rtl">
              <div className="max-w-12">
                {task.assignTo?.profileImg ? <img
                    className="w-10 h-10 rounded-full"
                    src={task.assignTo?.profileImg}
                    alt="profile"
                /> :  <Avatar alt={task.assignTo?.name}  />}
              </div>
              <div className="flex flex-col">
                <h3 className="text-[18px] font-medium">{task.assignTo?.name}</h3>
                <h4 className="text-[16px] text-gray-600">{task.assignTo?.role}</h4>
              </div>
            </div>
          </div>
          {index < tasks.length - 1 && (
            <hr className="border-t-2 border-gray-300 mb-6" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

export default TaskCardComponent;
