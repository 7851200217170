import React, { useState } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import MeetingItem from "./MeetingItem";
import MainPoints from "./MainPoints";
import TasksOFMeeting from "./TasksOFMeeting";
import MessagesOFMeeting from "./MessagesOFMeeting";
import AdditionsFilesOfMeeting from "./AdditionsFilesOFMeeting";
import "./MenuMeeting.css";

function MeetingMenu(props) {
  const [isMainPointModal, setIsMainPointModal] = useState(false);
  const [isTasksOfMeetingModal, setIsTasksOfMeetingModal] = useState(false);
  const [isMessagesOFMeetingModal, setIsMessagesOFMeetingModal] =
    useState(false);
  const [isFilesOFMeetingModal, setIsFilesOFMeetingModal] = useState(false);
  const handelMainPointModal = () => {
    setIsMainPointModal(!isMainPointModal);
  };
  const handelTasksOfMeetingModal = () => {
    setIsTasksOfMeetingModal(!isTasksOfMeetingModal);
  };
  const handelMessagesOFMeetingModal = () => {
    console.log("chat");
    setIsMessagesOFMeetingModal(!isMessagesOFMeetingModal);
  };
  const handelFilesOFMeetingModal = () => {
    setIsFilesOFMeetingModal(!isFilesOFMeetingModal);
  };
  return (
    <>
      <Box
        sx={{
          width: { md: "100%", sm: "100%" },
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box>
            <Typography variant="h6" gutterBottom>
              {props.data.title}
            </Typography>
            <Typography variant="body2">{props.data.date}</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Avatar
              alt="img"
              src="https://s3-alpha-sig.figma.com/img/13d6/b0c9/82c7d2fc62c1c24febf90f8c169e4a2a?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=AbXfxw89NySV4lIIve6imI6Blqf6Pr2q5r36ljl-alulxCrzllCp3eJ~BkKEbbpJoV-rD3OfbhKo81Nhx4S3dAUior6H-MaYNSTkPDZOp1vHmoHvoAsm3eZ991Q0l3i1cBe3PLFuZ5y4K9~3Ed4d-8VZh6mhlRcOrjEVdi97hZ8x1F-hAvPThPWERlxhrvCmspN74LXAheMcDtIH4bBfpFqDHJ9W~02iONnbSvu-dI6z-6rjE8IUxM-5HtkCmledjjxgSZO4-hloAHVYwgxNgp1agdGrYDqM9of4kKGVRnJDCrweNNxzMvhl1rx4QOYSoLvmdm34tzj5h95~g8P~hA__"
              sx={{ width: 30, height: 30 }}
            />
            <Avatar
              alt="img"
              src="https://s3-alpha-sig.figma.com/img/fd21/0de3/f17371c9f1b833eba9f9041b0160be3b?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nrnNFruB4zgulODUkqwJulZQ5TIjFHGLWdcEwfxN5WCQcBSORrj2EK6X67ajv5myjD0v5AWf9ym7KpZjOlkXIqnsX6yYf-A-108A16tV7VZL6mxGlw7UlC2459BoFZxd~e9sI4tJinoPSaAWPYGsFQbH~OQ-SWxfzVVpRWCnIyoroIS68AIiU3bcCFr8MZQxM98uEaMxOpCE88O5XQRYLEnRlSjWEIPHq4-H6aG4ZM066QCWbTZgrPYElKdkMAOrRalLUsAevNo6JIe6Nvsf6Gzz3gXvYt6n-SgoKwie3OwsbaqaIEX3FAgm1Iju-dMxmPWokHBDX-Q8AqI-yB1pxA__"
              sx={{ width: 30, height: 30 }}
            />
            <Avatar
              alt="img"
              src="https://s3-alpha-sig.figma.com/img/c12d/e910/1a81a693c82c970b0be2345d98c90c67?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TS~XBz5NFh12FCdPwefY4o8GLOggXY5UL6RVufL6N5V9PHC9yDxnV~Rw3jsi0dzjovuemlRJ--fLducLJ5XlmrRYolWJGy3YGvwLEg~iJhfattf3he5rzZukOtTXuFcKgBJ4HWCvJGfUoQgvqNkt8GjChSdn07kefAMD9N3MtIlye9vbH5KCUnoJoLPWClmHBI33TE0cchFXz6FPuGi6wx4DG8y3bUZuzJRAmk7CVr3wfshe~A2AngcCjzpo070XWS8RvJMGTrIjsZV8d~-7sc-jREFrQ8qTwarrMXrhfjNPSqDoQJtm~rDoaLCdG5A0SaesEgarmxkPHmVLBwS2HQ__"
              sx={{ width: 30, height: 30 }}
            />
            <Avatar
              alt="img"
              src="https://s3-alpha-sig.figma.com/img/532c/ba33/94a5cc08c967c81562f1b2a8369293f7?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=beqgV6Q9EaMlSK6M~9DRa7VjVXVf35Y-WVQP5~OO6Rq7-iLNDYncayQ8AemHtbqtzfnXc-wIc86iJ6ljZ~8MklZQ621wh5Rf6~y7Z9T8rM1izo3diZA8D5UDUFAtRImeXYsQ3Bv9wefdxSL-KFOHeXln82BukTsl0LaBFAAetCd0ZDeZ4dPs3ucquF6FJIbb-4URyG3xH1me-RtzrMuDjoiFrJFOoYrOqqg6b2EH4Nt6r9cFplIAB2ctYLifeI-dad~ZgHKuazxvbkO~rzGsLiql9H5Yo4b5I8mJveq7oOwshnYxMgeSOLUFbNTzgUQwVdNevJT25UwXFb8fEuXzGg__"
              sx={{ width: 30, height: 30 }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            padding: "70px 70px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
            <MeetingItem
              title="النقاط الرئيسية"
              onClick={handelMainPointModal}
              srcImg="https://s3-alpha-sig.figma.com/img/44a6/f717/6955d751eb23b244f7e36238701eca15?Expires=1725235200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=q9Nund-z6eVY7GniOaPxpCrml2wgdBQaYE2Srx3oPRpBA~iGssWUfvp7QkRBh1ij6y0lvb7x74JiJQAIXYEUNMopNwli-uDnP9NESkjSW4uVJfBxm31VXKl-MkYmrQFBZWL-auY4e8Xjq3prar9Zngyv8cSPSfd1wahY80ZwVctBrUbKXYMfFhhUZOGfharGTgT-51DBaF29XWioBY~dSuXY6us7qzR9vnqviC5Zjs5QP91zwyxGE1Och~qkoU-WcVHrt1IZ4LcvP4fynrq46cA9mKteusMUq6-~85kcQMEK4cKX4LO96WT8XTgaA-yHBWjpS8PepcoyXDVMIRK1PQ__"
            />
            <MeetingItem
              title={"المهمات"}
              onClick={handelTasksOfMeetingModal}
              srcImg={
                "https://s3-alpha-sig.figma.com/img/45f9/4bf3/f67d1bf242aafd7b618853e6a04b300d?Expires=1725235200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=c3ij8J0bfP5hxg9MObD4JdlzHIhQ-t1dSKKmSSfwu2xqBLYfblQT33rHUZOxU52hhXw5OHEYI4giBwXznOWNSq2ZyZMfzokuypl8FquQ4m8dyej9fLvPzEH97bQRatnYnl9hQWFm2H9p3hsFPTKTTC7hYrJX5iz3DyxOp-sYMqEbvFLiMXyXSoA77fW0t80VejAbnPGpK79xz74dp4DAxf6MfGOZNyjINTW1jNQiAZ0MjFckc5NjfCXAtmoinNf24DF5SZQIJrCIxAcXEegWvRaid5-ZjLd~SdEQHsK76tXduj~mBDN2LeFuXAQvrOTwpXVW3dLOztZvMJ9bWqLG2Q__"
              }
            />
            <MeetingItem
              title={"الملفات"}
              onClick={handelFilesOFMeetingModal}
              srcImg={
                "https://s3-alpha-sig.figma.com/img/6c6d/dfff/f214c1274ae9387139f105da8bfe886c?Expires=1725235200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=X10xK8kRBnOZKpeHuFC3BuPkmIh0nmqFvU8kKVMh~tWk1eDNLw5M1LDkxlGIovlj6HJhs2dJd~DCpgKl8Jbwmfr65siDPG6lydsHYPdRHVTEo8KRrAtgRuf7e6xdfMwcyrv2d41rfgE897QPtqhSMNoVAkJGRLyO2sywjLfU-ucGB1GRrg5fUynMGnQWy~7OMIlv4SCsrMk5ot0kUZoae90mzBsu1Ca1L8I-GWQpPAmm3-TT-l3lSje-~NVJ-XkW0l4TZ7mDQUmJG21VdijdogaxW~f5bOwrLKTAYkrbnXYTsxlOnpUQoifwZmGuS0qRnl-SIqYnqXlg0tlfD1p6qg__"
              }
            />
            <MeetingItem
              title={"المحادثات"}
              onClick={handelMessagesOFMeetingModal}
              srcImg={
                "https://s3-alpha-sig.figma.com/img/54e4/15de/9f657aa6e60c0aa1bbb131d66adeeb6c?Expires=1725235200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=HNLCWYrd1hbjvXleEUVpiBBLgPqA7e~h3GTBwx6SNQ~hXarC9f5rtKC3qHo4M46LUoWIjENA6Yi70tvKJ3vacNUQo0dR5~3ob~QSVJGI8OKhWvXIeessV6Ii9NlhqSqijgFaqNWJkQvSsvum3zq0-mkWO9A7MHcVUhgJcantrhHSccjnFRkSii7l55BIF~ZIZaKeY0kuoIBPdRiEX9OAvmgiL77l~l3ZeoJ2PVanDrRfT2aS9vWK7Mx3BpgPZkZak79ABxAovQA4cMH4pjxzhMNpWIOt4tsS6o1tJlPyf9aJM4hjiMq0mvFsAGNUxVv6AP-614-tMIzgjZhpnvsStQ__"
              }
            />
          </Box>
        </Box>
      </Box>

      <MainPoints
        isModalOpen={isMainPointModal}
        onClose={handelMainPointModal}
      />
      <TasksOFMeeting
        isModalOpen={isTasksOfMeetingModal}
        onClose={handelTasksOfMeetingModal}
      />
      <MessagesOFMeeting
        isModalOpen={isMessagesOFMeetingModal}
        onClose={handelMessagesOFMeetingModal}
        data={props.data}
      />
      <AdditionsFilesOfMeeting
        isModalOpen={isFilesOFMeetingModal}
        onClose={handelFilesOFMeetingModal}
      />
    </>
  );
}

export default MeetingMenu;
