import React, { useState, useEffect, useRef } from 'react';
import profileIcon from "../../assets/icons/profile.png";
import notificationIcon from "../../assets/icons/notification.png";
import messageIcon from "../../assets/icons/message.png";
import { IconButton } from '@mui/material';
import NotificationHeader from "./Notifications/NotificationHeader";
import NotificationButton from "./Notifications/NotificationButton";
import MessageBtn from "./Message/MessageBtn";
import MessagesHeader from "./Message/MessagesHeader";
import socket from "../../socket";
import {useDispatch, useSelector} from "react-redux";
import AddMessageAction from "../../redux/action/Conversation/AddMessage.action";
import CreateNotificationAction from "../../redux/action/Notification/NewNotificationaction";

function HeaderComponent(props) {
    const [isOpenNotification, setIsOpenNotification] = useState(false);
    const [isOpenMessage, setIsOpenMessage] = useState(false);
    const dropdownNotificationRef = useRef(null);
    const dropdownMessageRef = useRef(null);
    const user = useSelector((state) => state.user.data);
    const dispatch = useDispatch();

    const toggleDropdownNotification = () => {
        setIsOpenNotification(!isOpenNotification);
    };

    const toggleDropdownMessages = () => {
        setIsOpenMessage(!isOpenMessage);
    };

    const handleClickOutside = (event) => {
        if (dropdownNotificationRef.current && !dropdownNotificationRef.current.contains(event.target)) {
            setIsOpenNotification(false);
        }
        if (dropdownMessageRef.current && !dropdownMessageRef.current.contains(event.target)) {
            setIsOpenMessage(false);
        }
    };

    useEffect(() => {
        socket.emit("auth", {
            user_id: user._id,
        });

        socket.on("getMessages", (data) => {
            dispatch(AddMessageAction(data));
        });

        socket.on("getNotification", (notification) => {
            dispatch(CreateNotificationAction(notification.data));
        });

        return () => {
            socket.off("getMessages");
            socket.off("getNotification");
        };

    }, []);


    useEffect(() => {
        if (isOpenNotification || isOpenMessage) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpenNotification, isOpenMessage]);

    return (
        <>
            <div className="header p-10 mx-auto my-6 flex flex-row-reverse w-10/12 justify-between items-center h-10">
                <div className={"flex gap-3 flex-row-reverse"}>
                    <div className={"max-w-10"}>
                        <img className="max-w-full" src={profileIcon} alt="logo"/>
                    </div>
                    <div className={"text-profile"}>
                        <h6>Admin</h6>
                        <h6>مسؤول</h6>
                    </div>
                </div>
                <div className={"events flex gap-3 relative"}>
                    <div className="relative" ref={dropdownNotificationRef}>
                        <NotificationButton onClick={toggleDropdownNotification}/>
                        <NotificationHeader isOpen={isOpenNotification}/>
                    </div>
                    <div className="relative" ref={dropdownMessageRef}>
                        <MessageBtn onClick={toggleDropdownMessages}/>
                        <MessagesHeader isOpen={isOpenMessage}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeaderComponent;
