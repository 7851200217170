import React from 'react';
import TweeterSocial from "./components/Taps/Tweeter.social";
import TapsComponent from "../../Components/Taps.component";

function SocialMediaPage(props) {
    return (
        <TapsComponent
            firstTapTitle={"تويتر"}
            secondTapTitle={"انستجرام"}
            thirdTapTitle={"فيسبوك"}
            FourthTapTitle={"جيميل"}
            FifthTapTitle={"يوتيوب"}
            firstTapComponent={<TweeterSocial />}
            SecondTapComponent={<TweeterSocial />}
            ThirdTapComponent={<div></div>}
            FourthTapComponent={<div></div>}
        />
    );
}

export default SocialMediaPage;