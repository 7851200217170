// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  background-color: #ffffff;
  padding: 16px !important;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  direction: rtl; /* Right-to-left text direction */
}
.popHead {
  text-align: center;
  margin-bottom: 16px;
}
.field {
  margin-bottom: 16px !important;
  width: 100%;
  background-color: #49869633;
}

.description-field {
  margin-bottom: 24px !important;
  width: 100%;
  background-color: #49869633;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}

.close-button {
  position: absolute !important;
  top: 10px !important;
  left: 0px !important;
  cursor: pointer;
  font-size: 1.5rem !important;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #166477c3;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManageTasks/components/thirdUtils/styles/ReturnTaskModal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,yBAAyB;EACzB,wBAAwB;EACxB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,cAAc,EAAE,iCAAiC;AACnD;AACA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,8BAA8B;EAC9B,WAAW;EACX,2BAA2B;AAC7B;;AAEA;EACE,8BAA8B;EAC9B,WAAW;EACX,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;EAC7B,oBAAoB;EACpB,oBAAoB;EACpB,eAAe;EACf,4BAA4B;EAC5B,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":[".modal {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.content {\n  background-color: #ffffff;\n  padding: 16px !important;\n  border-radius: 8px;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  direction: rtl; /* Right-to-left text direction */\n}\n.popHead {\n  text-align: center;\n  margin-bottom: 16px;\n}\n.field {\n  margin-bottom: 16px !important;\n  width: 100%;\n  background-color: #49869633;\n}\n\n.description-field {\n  margin-bottom: 24px !important;\n  width: 100%;\n  background-color: #49869633;\n}\n\n.button-container {\n  display: flex;\n  justify-content: center;\n  margin-top: 0.5rem;\n}\n\n.close-button {\n  position: absolute !important;\n  top: 10px !important;\n  left: 0px !important;\n  cursor: pointer;\n  font-size: 1.5rem !important;\n  width: 24px;\n  height: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #166477c3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
