import React from "react";

function TaskCalendarComponent({task}) {
    return (
        <div className={"w-full flex-col bg-[#4986961A] gap-2 p-3 rounded-lg font-family-primary"}>
            <div className={"flex w-full justify-between items-baseline"}>
                <text className={"font-bold text-[16px] text-center "}>{task.name}</text>
                <div className={"flex justify-center items-center h-full"}>
                    {task.isDone ? (
                        <div
                            className={" bg-primary text-white  rounded-lg px-2 h-[1.2rem] flex items-center justify-center"}>
                            <text className={"text-[16px]"}>مسلمة</text>
                        </div>
                    ) : (
                        <div
                            className={" bg-[#49869699] text-white rounded-lg px-2 h-[1.2rem] flex items-center justify-center"}>
                            <text className={"text-[16px]"}>غير مسلمه</text>
                        </div>
                    )
                    }
                </div>

            </div>
            <div className={"flex items-baseline gap-1"}>
                <div
                    className={"rounded-full bg-primary h-2"}
                    style={{padding: "4px"}}
                ></div>
                <text className={"text-[16px]"}>
                    <span dangerouslySetInnerHTML={{__html: task.description}}/>
                </text>
            </div>

        < /div>
    );
}

export default TaskCalendarComponent;
