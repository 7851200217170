import React, { useState } from 'react';
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TextInput from "../../../Components/Supcomponents/inputs/TextInput";
import DefaultBtn from "../../../Components/Supcomponents/Buttons/DefaultBtn"; // "+" icon

const NewListTasks = (props) => {
    const [isCreateList, setIsCreateList] = useState(false);

    return (
        !isCreateList ? (
            <div className={"bg-[#4986961A] font-family-primary rounded-[12px] h-full max-w-[25rem] " + props.className}>
                <div className={"hover:bg-[#E3E9EA] cursor-pointer rounded-md"} onClick={() => setIsCreateList(true)}>
                    <div className="w-full flex font-family-primary text-[24px] justify-center py-2 items-center gap-2">
                        <AddIcon />
                        اضافة قائمة مهام
                    </div>
                </div>
            </div>
        ) : (
            <div className={"bg-white flex flex-col gap-2 font-family-primary rounded-[12px] h-full max-w-[25rem] " + props.className}>

                <div className={"w-full"}>
                    <TextInput
                        component={TextInput}
                        name="name"
                        classNameInput={"rounded-[6px]"}
                        title="اسم قائمة المهام"
                        onChange={() => {
                        }}
                    />
                </div>
                <div className={"flex flex-row-reverse gap-2 "}>
                    <DefaultBtn
                        classBtn={"bg-primary text-white w-20 rounded-[6px]"}
                        title={"حفظ"}
                        type="submit"
                    />
                    <button
                        type="button"
                        className="z-10 cursor-pointer text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        onClick={() => setIsCreateList(false)}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
            </div>
        )
    );
}

export default NewListTasks;
