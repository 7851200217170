// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: LTR !important; /* Left-to-right text direction */
  border: none; /* Ensure no border around the content */
}

.button-container {
  margin-top: 1rem;
  display: flex;
}

.rating-icon-filled {
  color: #ffb400;
}

.rating-icon-empty {
  color: #ccc;
}

.rating-icon {
  border: none; /* Ensure no border around stars */
}

.submit-button {
  background-color: #49869633 !important;
  color: #000 !important;
  border: none !important;
  margin-top: 10px !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManageTasks/components/thirdUtils/styles/RateModal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB,EAAE,iCAAiC;EAC5D,YAAY,EAAE,wCAAwC;AACxD;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY,EAAE,kCAAkC;AAClD;;AAEA;EACE,sCAAsC;EACtC,sBAAsB;EACtB,uBAAuB;EACvB,2BAA2B;AAC7B","sourcesContent":[".modal {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.content {\n  background-color: #ffffff;\n  padding: 16px;\n  border-radius: 8px;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  direction: LTR !important; /* Left-to-right text direction */\n  border: none; /* Ensure no border around the content */\n}\n\n.button-container {\n  margin-top: 1rem;\n  display: flex;\n}\n\n.rating-icon-filled {\n  color: #ffb400;\n}\n\n.rating-icon-empty {\n  color: #ccc;\n}\n\n.rating-icon {\n  border: none; /* Ensure no border around stars */\n}\n\n.submit-button {\n  background-color: #49869633 !important;\n  color: #000 !important;\n  border: none !important;\n  margin-top: 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
