import React, {useState} from "react";
import {
    Box,
    IconButton,
    Button,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"; // Three dots icon
import AddIcon from "@mui/icons-material/Add"; // "+" icon
import "./styles.css";
import TaskCardComponent from "./TaskCardComponent";
import CreateTaskModal from "../../../modals/Tasks/CreateTask.modal";
import InlineEditing from 'react-inline-editing';
function ListTasksComponent(props) {
    const [isCreateTaskOpen, setIsCreateTaskOpen] = useState(false);
    const [text, setText] = useState('نشر محتوى');

    const displayCreateTaskModal = () => {
        setIsCreateTaskOpen(!isCreateTaskOpen);
    }

    const handleFocusOut = (newText) => {
        setText(newText);
    };
    return (
        <>
        <div className={"task-list-container " + props.className}>
            <Box className="task-list-header">
                <InlineEditing
                    text={text}
                    inputMaxLength={50}
                    onFocusOut={handleFocusOut}
                    inputClassName={"rounded-[6px]"}
                    isRequired
                />
                <IconButton aria-label="more" size="small">
                    <MoreHorizIcon />
                </IconButton>
            </Box>
            <Box
                sx={{
                    flex: 1, // Take up the available space
                    overflowY: "auto", // Allow scrolling if content overflows
                }}
            >
                {props.tasks?.map((task, index) => (
                    <TaskCardComponent key={index} task={task} />
                ))}
            </Box>
            <Box className="w-full ">
                <div onClick={displayCreateTaskModal} className={"hover:bg-[#E3E9EA] cursor-pointer rounded-md"}>
                    <div className="w-full flex px-2 font-family-primary text-[24px] justify-start items-center gap-2">
                        <AddIcon />
                        اضافة مهمة جديدة
                    </div>
                </div>
            </Box>
        </div>
            <CreateTaskModal isModalOpen={isCreateTaskOpen} onClose={displayCreateTaskModal} />
        </>
    );
}

export default ListTasksComponent;